import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../home/Footer";

export default function SinglePlan() {
    const { id } = useParams(); // Get the ID from the URL

    // Array of plans
    const plans = [
        {
            id: "1",
            plan_name: "Basic Plan",
            year_price: "1,499",
            renewal: "1,499",
            monthly_price: "149",
            no_of_shopes: 1,
            no_of_pos_shopes: 2,
            vansale_mobileApp_licence: 0,
            accounts_module: true,
            purchase_module: true,
            inventory_module: true,
            hrms_module: false,
            ecommerce_module: false,
            manufacturing_warehouse_module: false,
            company_website: false,
            additional_shop_cost: "200/year",
            additional_posShop_cost: "100/year",
            additional_mobApp_cost: "300/year",
        },
        {
            id: "2",
            plan_name: "Standard Plan",
            year_price: "5,999",
            renewal: "4,499",
            monthly_price: "599",
            no_of_shopes: 5,
            no_of_pos_shopes: 2,
            vansale_mobileApp_licence: 10,
            accounts_module: true,
            purchase_module: true,
            inventory_module: true,
            hrms_module: true,
            ecommerce_module: true,
            manufacturing_warehouse_module: false,
            company_website: false,
            additional_shop_cost: "500/year",
            additional_posShop_cost: "300/year",
            additional_mobApp_cost: "400/year",
        },
        {
            id: "3",
            plan_name: "Premium Plan",
            year_price: "11,999",
            renewal: "8,999",
            monthly_price: "1,199",
            no_of_shopes: 10,
            no_of_pos_shopes: 3,
            vansale_mobileApp_licence: 25,
            accounts_module: true,
            purchase_module: true,
            inventory_module: true,
            hrms_module: true,
            ecommerce_module: true,
            manufacturing_warehouse_module: true,
            company_website: true,
            additional_shop_cost: "800/year",
            additional_posShop_cost: "500/year",
            additional_mobApp_cost: "700/year",
        },
    ];

    const tickIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
    )

    const cancelIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
    )

    // Find the plan by ID
    const plan = plans.find((p) => p.id === id);

    if (!plan) {
        return <div className="text-center text-red-500 mt-20">Plan not found!</div>;
    }

    return (
        <div className="md:px-20 px-5">
            <div className="min-h-screen font-lato bg-black text-white flex flex-col items-center md:py-24 pt-20">
                <h1 className="md:text-4xl text-2xl font-light md:mb-10 mb-5">{plan.plan_name}</h1>
                <div className="bg-gray-900 rounded-xl shadow-md md:py-8 py-5 flex flex-col gap-5 w-full max-w-5xl">
                    {/* Plan Pricing */}
                    {/* <div className="grid px-5 py-2 md:py-5 bg-[#ffffff43] grid-cols-3 text-start md:ps-20 md:gap-8 gap-5 md:mb-5">
                        <div>
                            <p className="md:text-xl text-sm font-semibold mb-3">Yearly - Price :</p>
                            <p className="text-green-500 md:text-2xl text-base font-semibold">AED {plan.year_price}</p>
                        </div>
                        <div>
                            <p className="md:text-xl text-sm font-semibold mb-3">Renewal (Yearly):</p>
                            <p className="text-green-500 md:text-2xl text-base font-semibold">AED {plan.renewal}</p>
                        </div>
                        <div>
                            <p className="md:text-xl text-sm font-semibold mb-3">Monthly - Price:</p>
                            <p className="text-green-500 md:text-2xl text-base font-semibold">AED {plan.monthly_price}</p>
                        </div>
                    </div> */}

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-16 ps-5 pe-5 md:pe-0">
                        {/* Plan Features */}
                        <div className="col-span-2 gap-4 md:ps-20">
                            <h2 className="w-full md:text-2xl text-xl font-semibold mb-3 text-start ">Plan Features:</h2>
                            <div className="grid grid-cols-1 md:gap-5 md:grid-cols-2 text-start">
                                <table>
                                    <tr>
                                        <td>Shopes : </td>
                                        <td className="text-center text-green-500 font-semibold">{plan.no_of_shopes}</td>
                                    </tr>
                                    <tr>
                                        {
                                            plan.id==1?
                                            <td>POS :</td>
                                            :
                                            <td>POS / Shops :</td>
                                        }
                                        <td className="text-center text-green-500 font-semibold">{plan.no_of_pos_shopes}</td>
                                    </tr>
                                    <tr>
                                        <td>VAN Sale License :</td>
                                        <td className="text-center text-green-500 font-semibold">{plan.vansale_mobileApp_licence}</td>
                                    </tr>
                                    <tr>
                                        <td>Accounts Module :</td>
                                        <td className={`flex justify-center ${plan.accounts_module ? "text-green-500 font-semibold" : "text-red-500 font-semibold"}`}>
                                            {plan.accounts_module ? tickIcon : cancelIcon}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Purchase Module :</td>
                                        <td className={`flex justify-center 
                                            ${plan.purchase_module
                                                ? "text-green-500 font-semibold text-center"
                                                : "text-red-500 font-semibold text-center"
                                            }`}>{plan.purchase_module ? tickIcon : cancelIcon}</td>
                                    </tr>
                                    <tr>
                                        <td>Inventory Module :</td>
                                        <td className={`flex justify-center
                                            ${plan.inventory_module
                                                ? "text-green-500 font-semibold text-center"
                                                : "text-red-500 font-semibold text-center"
                                            }`}>{plan.inventory_module ? tickIcon : cancelIcon}</td>
                                    </tr>
                                </table>

                                <table>
                                    <tr>
                                        <td>HRMS Module :</td>
                                        <td className={
                                            plan.hrms_module
                                                ? "text-green-500 font-semibold"
                                                : "text-red-500 font-semibold"
                                        }>{plan.hrms_module ? tickIcon : cancelIcon}</td>
                                    </tr>
                                    <tr>
                                        <td>E-commerce Module :</td>
                                        <td className={
                                            plan.ecommerce_module
                                                ? "text-green-500 font-semibold"
                                                : "text-red-500 font-semibold"
                                        }>{plan.ecommerce_module ? tickIcon : cancelIcon}</td>
                                    </tr>
                                    <tr>
                                        <td> Manufacturing/Warehouse :</td>
                                        <td className={
                                            plan.manufacturing_warehouse_module
                                                ? "text-green-500 font-semibold"
                                                : "text-red-500 font-semibold"
                                        }>{plan.manufacturing_warehouse_module ? tickIcon : cancelIcon}</td>
                                    </tr>
                                    <tr>
                                        <td> Comapany Website :</td>
                                        <td className={
                                            plan.company_website
                                                ? "text-green-500 font-semibold"
                                                : "text-red-500 font-semibold"
                                        }>{plan.company_website ? tickIcon : cancelIcon}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={
                                            plan.company_website
                                                ? "text-green-500 font-semibold"
                                                : "text-red-500 font-semibold"
                                        }>{plan?.accounts_module ? "" : ""}</td>
                                    </tr>
                                </table>

                            </div>
                        </div>

                        {/* Additional Costs */}
                        <div className="space-y-1 text-start">
                            <h2 className="md:text-2xl text-xl font-semibold mt-8 mb-3">Additional Costs:</h2>
                            <p>
                                Additional Shop:{" "}
                                <span className="text-green-500">
                                    {/* {plan.additional_shop_cost} */}
                                    N/A
                                    </span>
                            </p>
                            <p>
                                Additional POS Shop:{" "}
                                <span className="text-green-500">
                                    {/* {plan.additional_posShop_cost} */}
                                    N/A
                                    </span>
                            </p>
                            <p>
                                Additional VAN License:{" "}
                                <span className="text-green-500">
                                    {/* {plan.additional_mobApp_cost} */}
                                    N/A
                                    </span>
                            </p>
                            <button className="md:py-3 py-2 md:rounded-s-xl md:rounded-e-none rounded-md text-lg md:mx-0 !mt-10 w-full px-5 bg-[#083445] hover:bg-teal-800 transition-all duration-200">
                                Purchase
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}
