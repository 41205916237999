import React, { useState, useEffect } from "react";
import { IconBaselineDensityMedium } from "@tabler/icons-react";
import { Sidebar } from "primereact/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  // Language configurations
  const languages = [
    { code: "en", lang: "English" },
    { code: "ar", lang: "Arabic" }
  ];

  // Change language function
  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    document.documentElement.lang = code;
    // document.documentElement.dir = code === "ar" ? "rtl" : "ltr";
  };

  // Handle toggle with language change
  const handleToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    // Change language based on toggle state
    const languageCode = newCheckedState ? "ar" : "en";
    changeLanguage(languageCode);
  };

  const translations = {
    company_name: t("navbar.company_name", { returnObjects: true }),
    home: t("navbar.home"),
    about: t("navbar.about"),
    service: t("navbar.service"),
    solution: t("navbar.solution"),
    // pricing: t("navbar.pricing"),
    dropdownItems: [
      { label: t("navbar.dropdown.contact"), value: "/contact" },
      { label: t("navbar.dropdown.blog"), value: "/blog" },
      { label: t("navbar.dropdown.careers"), value: "/careers" },
      { label: t("navbar.dropdown.faqs"), value: "/faqs" },
      { label: t("navbar.dropdown.support"), value: "/support" },
      { label: t("navbar.dropdown.admin"), value: "/admin" },
    ],
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setSelectedOption(location.pathname);
    }
  }, [location.pathname]);

  const handleOptionClick = (option) => {
    setIsOpen(false);
    navigate(option);
  };

  const customIcons = (
    <React.Fragment>
      <button className="p-sidebar-icon p-link mr-2"></button>
    </React.Fragment>
  );

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <h1 className="text-2xl font-lato font-bold">
        {translations.company_name.white}<span className="text-blue-400">{translations.company_name.blue}.</span>
      </h1>
    </div>
  );

  return (
    <div className="w-full md:h-[70px] h-[50px] z-[200] bg-transparent backdrop-blur-sm fixed top-0 text-white flex justify-between items-center pe-0 md:pe-10 md:ps-0">
      <style>{`
        .slide-in p {
          opacity: 0;
          transform: translateY(-20px);
          animation: slideIn 0.5s ease forwards;
          animation-fill-mode: forwards;
        }

        .slide-in p:nth-child(1) { animation-delay: 0s; }
        .slide-in p:nth-child(2) { animation-delay: 0.2s; }
        .slide-in p:nth-child(3) { animation-delay: 0.4s; }
        .slide-in p:nth-child(4) { animation-delay: 0.6s; }
        .slide-in p:nth-child(5) { animation-delay: 0.8s; }
        .slide-in p:nth-child(6) { animation-delay: 1s; }
        .slide-in p:nth-child(7) { animation-delay: 1.2s; }
        .slide-in p:nth-child(8) { animation-delay: 1.4s; }
        .slide-in p:nth-child(9) { animation-delay: 1.6s; }
        .slide-in p:nth-child(10) { animation-delay: 1.8s; }
        .slide-in p:nth-child(11) { animation-delay: 2s; }
        .slide-in p:nth-child(12) { animation-delay: 2.2s; }

        @keyframes slideIn {
          0% { opacity: 0; transform: translateY(-20px); }
          100% { opacity: 1; transform: translateY(0); }
        }

        select {
          background-color: transparent;
          color: white;
          border: none;
          padding: 5px;
          font-size: 1rem;
          appearance: none;
        }

       select:focus {
        --tw-ring-color: #1C64F2;
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        border-color: transparent;
        box-shadow: none;
        outline: none;
        }

        option {
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
        }
      `}</style>

      {/* Mobile Version: Below 640px */}
      <div
        className="sm:hidden w-full flex justify-between items-center ps-16 pe-5 h-full bg-[rgba(217,217,217,0.4)]"
        style={{ boxShadow: "0px 0px 20px rgba(217, 217, 217, 0.2)" }}
      >
        <button
          className="absolute left-7 md:top-7 top-4 text-blue-400"
          onClick={() => setVisible(true)}
        >
          <IconBaselineDensityMedium
            stroke={2}
            width={20}
            height={20}
            className="w-5 h-5"
          />
        </button>
        <div className="flex justify-center items-center gap-1">
          <img src="/assets/logo.png" alt="" className="w-[30px] h-[30px]" />
          <h1 className="text-2xl font-lato font-bold">
            {translations.company_name.white}<span className="text-blue-400">{translations.company_name.blue}.</span>
          </h1>
        </div>

        <div>
          {/* Toggle Button*/}
          <div
            className="relative h-[1.3em] aspect-[292/142] cursor-pointer"
            onClick={handleToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 292 142"
              className="w-full h-full overflow-visible"
            >
              {/* Background Path */}
              <path
                d="M71 142C31.7878 142 0 110.212 0 71C0 31.7878 31.7878 0 71 0C110.212 0 119 30 146 30C173 30 182 0 221 0C260 0 292 31.7878 292 71C292 110.212 260.212 142 221 142C181.788 142 173 112 146 112C119 112 110.212 142 71 142Z"
                className={`transition-colors duration-1000 ${isChecked ? "fill-[#1868e3]" : "fill-[#d3d3d6]"}`}
              ></path>

              {/* Toggle Text */}
              <text
                x="70"
                y="85"
                textAnchor="middle"
                fontSize="50"
                fontWeight="bold"
                fill={isChecked ? "white" : "#d3d3d6"}
                className="transition-colors duration-1000"
              >
                {isChecked ? "EN" : "AR"}
              </text>

              <text
                x="220"
                y="85"
                textAnchor="middle"
                fontSize="50"
                fontWeight="bold"
                fill={isChecked ? "white" : "blue"}
                className="transition-colors duration-1000"
              >
                {isChecked ? "EN" : "AR"}
              </text>


              {/* Moving Circle */}
              <g filter="url(#goo)">
                <rect
                  fill="#fff"
                  rx="29"
                  height="58"
                  width="116"
                  y="42"
                  x="13"
                  className={`transition-transform duration-1000 ${isChecked ? "translate-x-[150px]" : "translate-x-0"
                    }`}
                ></rect>

                {/* Left Circle */}
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="14"
                  className={`transition-transform duration-800 transform-origin-center ${isChecked ? "scale-0" : "scale-100"
                    }`}
                ></rect>

                {/* Right Circle */}
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="164"
                  className={`transition-transform duration-800 transform-origin-center ${isChecked ? "scale-100" : "scale-0"
                    }`}
                ></rect>
              </g>

              {/* Gooey Filter */}
              <filter id="goo">
                <feGaussianBlur
                  stdDeviation="10"
                  result="blur"
                  in="SourceGraphic"
                ></feGaussianBlur>
                <feColorMatrix
                  result="goo"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  mode="matrix"
                  in="blur"
                ></feColorMatrix>
              </filter>
            </svg>
          </div>
        </div>

        <Sidebar
          header={customHeader}
          visible={visible}
          onHide={() => setVisible(false)}
          icons={customIcons}
          className="text backdrop-blur-sm text-gray-300 max-w-[230px] sm:max-w-[280px] z-30"
          style={{
            backgroundColor: "rgba(217, 217, 217, 0.2)",
            boxShadow: "0px 0px 20px rgba(217, 217, 217, 0.2)",
          }}
          transitionOptions={{ duration: 1000, easing: "ease-in-out" }}
        >
          <div className="w-full flex flex-col gap-7 mt-10 slide-in items-end pe-8 font-lato font-light">
            <p>
              <Link
                to="/home"
                onClick={() => handleOptionClick("/home")}
                className={`text ${selectedOption === "/home" ? "text-blue-400" : ""
                  }`}
              >
                {translations.home}
              </Link>
            </p>
            <p>
              <Link
                to="/about"
                onClick={() => handleOptionClick("/about")}
                className={`text ${selectedOption === "/about" ? "text-blue-400" : ""
                  }`}
              >
                {translations.about}
              </Link>
            </p>
            <p>
              <Link
                to="/services"
                onClick={() => handleOptionClick("/services")}
                className={`text ${selectedOption === "/services" ? "text-blue-400" : ""
                  }`}
              >
                {translations.service}
              </Link>
            </p>
            <p>
              <Link
                to="/solutions"
                onClick={() => handleOptionClick("/solutions")}
                className={`text ${selectedOption === "/solutions" ? "text-blue-400" : ""
                  }`}
              >
                {translations.solution}
              </Link>
            </p>
            {/* <p>
              <Link
                to="/pricing"
                onClick={() => handleOptionClick("/pricing")}
                className={`text ${selectedOption === "/pricing" ? "text-blue-400" : ""
                  }`}
              >
                {translations.pricing}
              </Link>
            </p> */}
            {translations.dropdownItems.map((item) => (
              <p key={item.value}>
                <Link
                  to={item.value}
                  onClick={() => handleOptionClick(item.value)}
                  className={`text ${selectedOption === item.value ? "text-blue-400" : ""
                    }`}
                >
                  {item.label}
                </Link>
              </p>
            ))}
          </div>
        </Sidebar>
      </div>

      {/* Desktop Version: Above 640px */}
      <div className="hidden sm:flex w-full justify-between items-center h-full ">
        <div
          className="flex gap-1 justify-start md:px-20 px-5  items-center w-1/3 h-full bg-[rgba(217,217,217,0.5)]"
          style={{ clipPath: "polygon(0 0, 100% 0%, 90% 100%, 0% 100%)" }}
        >
          <img src="/assets/logo.png" alt="" className="w-[40px] h-[40px]" />
          <h1 className="text-2xl font-lato font-bold">
            {translations.company_name.white}<span className="text-blue-400">{translations.company_name.blue}.</span>
          </h1>
        </div>

        <div className="flex space-x-6 md:space-x-12 font-lato font-light pe-5">
          <Link
            to="/home"
            onClick={() => handleOptionClick("/home")}
            className={`hover:text-blue-400 transition duration-300 ${selectedOption === "/home" ? "text-blue-400" : ""
              }`}
          >
            {translations.home}
          </Link>
          <Link
            to="/about"
            onClick={() => handleOptionClick("/about")}
            className={`hover:text-blue-400 transition duration-300 ${selectedOption === "/about" ? "text-blue-400" : ""
              }`}
          >
            {translations.about}
          </Link>
          <Link
            to="/services"
            onClick={() => handleOptionClick("/services")}
            className={`hover:text-blue-400 transition duration-300 ${selectedOption === "/services" ? "text-blue-400" : ""
              }`}
          >
            {translations.service}
          </Link>
          <Link
            to="/solutions"
            onClick={() => handleOptionClick("/solutions")}
            className={`hover:text-blue-400 transition duration-300 ${selectedOption === "/solutions" ? "text-blue-400" : ""
              }`}
          >
            {translations.solution}
          </Link>
          <Link
            to="/pricing"
            onClick={() => handleOptionClick("/pricing")}
            className={`hover:text-blue-400 transition duration-300 ${selectedOption === "/pricing" ? "text-blue-400" : ""
              }`}
          >
            {translations.pricing}
          </Link>

          {/* Toggle Button*/}
          <div
            className="relative h-[1.3em] aspect-[292/142] cursor-pointer"
            onClick={handleToggle}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 292 142"
              className="w-full h-full overflow-visible"
            >
              {/* Background Path */}
              <path
                d="M71 142C31.7878 142 0 110.212 0 71C0 31.7878 31.7878 0 71 0C110.212 0 119 30 146 30C173 30 182 0 221 0C260 0 292 31.7878 292 71C292 110.212 260.212 142 221 142C181.788 142 173 112 146 112C119 112 110.212 142 71 142Z"
                className={`transition-colors duration-1000 ${isChecked ? "fill-[#1868e3]" : "fill-[#d3d3d6]"}`}
              ></path>

              {/* Toggle Text */}
              <text
                x="70"
                y="85"
                textAnchor="middle"
                fontSize="50"
                fontWeight="bold"
                fill={isChecked ? "white" : "#d3d3d6"}
                className="transition-colors duration-1000"
              >
                {isChecked ? "EN" : "AR"}
              </text>

              <text
                x="220"
                y="85"
                textAnchor="middle"
                fontSize="50"
                fontWeight="bold"
                fill={isChecked ? "white" : "blue"}
                className="transition-colors duration-1000"
              >
                {isChecked ? "EN" : "AR"}
              </text>


              {/* Moving Circle */}
              <g filter="url(#goo)">
                <rect
                  fill="#fff"
                  rx="29"
                  height="58"
                  width="116"
                  y="42"
                  x="13"
                  className={`transition-transform duration-1000 ${isChecked ? "translate-x-[150px]" : "translate-x-0"
                    }`}
                ></rect>

                {/* Left Circle */}
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="14"
                  className={`transition-transform duration-800 transform-origin-center ${isChecked ? "scale-0" : "scale-100"
                    }`}
                ></rect>

                {/* Right Circle */}
                <rect
                  fill="#fff"
                  rx="58"
                  height="114"
                  width="114"
                  y="14"
                  x="164"
                  className={`transition-transform duration-800 transform-origin-center ${isChecked ? "scale-100" : "scale-0"
                    }`}
                ></rect>
              </g>

              {/* Gooey Filter */}
              <filter id="goo">
                <feGaussianBlur
                  stdDeviation="10"
                  result="blur"
                  in="SourceGraphic"
                ></feGaussianBlur>
                <feColorMatrix
                  result="goo"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                  mode="matrix"
                  in="blur"
                ></feColorMatrix>
              </filter>
            </svg>
          </div>

          <div className="relative inline-block text-left">
            {/* Dropdown toggle button */}
            <button className="text-white" onClick={() => setIsOpen(!isOpen)}>
              <IconBaselineDensityMedium
                stroke={2}
                width={20}
                height={20}
                className="w-5 h-5"
              />
            </button>

            {/* Dropdown menu */}
            <div
              className={`absolute right-0 mt-2 w-40 bg-gray-800 rounded-lg shadow-lg z-50 transition-all duration-300 ease-out transform ${isOpen
                ? "opacity-100 scale-100"
                : "opacity-0 scale-95 pointer-events-none"
                }`}
            >
              <ul className="py-1 text-white">
                {translations.dropdownItems.map((item) => (
                  <li key={item.value}>
                    <button
                      onClick={() => handleOptionClick(item.value)}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-700"
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
